import { ApolloLink, HttpLink } from '@apollo/client'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import axios from 'axios'

const httpLink: ApolloLink = new HttpLink({
  uri: `${globalThis.process?.env?.ENPAL_PUBLIC_API_GATEWAY_URL}/graphql`,
  fetch: buildAxiosFetch(axios, (config, _input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })) as unknown as WindowOrWorkerGlobalScope['fetch'],
})

export default httpLink
