import ErrorBase from './Base'

export default class GraphQLError extends ErrorBase {
  operation: string

  constructor(message: string, code: number, operation: string) {
    super(message, code)
    this.name = 'GraphQLError'
    this.operation = operation
  }
}
