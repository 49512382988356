import { gql } from '@apollo/client'

export const UPDATE_FIELD = gql`
  mutation UpdateField(
    $avgElectricityUsePerYearKwh: Float
    $monthlyEnergyCost: Float
    $areAllRegisteredOwnersUnder70: String
    $registeredOwnersCount: String
  ) {
    updateLead(
      avgElectricityUsePerYearKwh: $avgElectricityUsePerYearKwh
      monthlyEnergyCost: $monthlyEnergyCost
      areAllRegisteredOwnersUnder70: $areAllRegisteredOwnersUnder70
      registeredOwnersCount: $registeredOwnersCount
    ) {
      disqualifyingAnswers
    }
  }
`

export const LOGIN = gql`
  mutation Login($loginCode: String!) {
    login(loginCode: $loginCode) {
      access_token
      refresh_token
    }
  }
`

export const REFRESH = gql`
  mutation RefreshToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      access_token
      refresh_token
    }
  }
`

export const CUSTOMER_ID = gql`
  query GetCustomerId {
    me {
      id
    }
  }
`

export const LOGIN_MUTATION_EMAIL = gql`
  mutation GetMagicLink(
    $emailAddress: String!
    $redirectTo: LoginRedirectOptions
  ) {
    sendLoginCodeViaEmail(
      emailAddress: $emailAddress
      redirectTo: $redirectTo
    ) {
      success
    }
  }
`

export const LOGIN_MUTATION_PHONE = gql`
  mutation GetMagicLinkByPhoneNumber($phoneNumber: String!) {
    sendLoginCodeViaSmsByPhoneNumber(phoneNumber: $phoneNumber) {
      success
    }
  }
`

export const SEND_IMAGE = gql`
  mutation SendImage(
    $category: ImageCategory!
    $imageFile: Upload!
    $prefix: String
  ) {
    uploadLeadImage(
      category: $category
      imageFile: $imageFile
      prefix: $prefix
    ) {
      imageId
    }
  }
`
export const SEND_IMAGE_PUBLIC = gql`
  mutation SendImagePublic(
    $category: ImageCategory!
    $imageFile: Upload!
    $prefix: String
    $userId: String!
  ) {
    uploadLeadImagePublic(
      category: $category
      imageFile: $imageFile
      prefix: $prefix
      userId: $userId
    ) {
      imageId
    }
  }
`

export const LEAD_IMAGES_FRAGMENT = gql`
  fragment LeadImageFields on LeadImageModel {
    category
    contentType
    id
    uploadedAt
    url
  }
`

export const LEAD_DATA_FRAGMENT = gql`
  fragment LeadDataFields on LeadData {
    disqualifyingAnswers
    id
    isQualified
    lastName
    avgElectricityUsePerYearInkWh
    monthlyElectricityCost
    registeredOwnersCount
    registeredOwnersUnder70
    isOSWLead
  }
`

export const DELETE_IMG = gql`
  mutation DeleteImage($imageId: String!) {
    deleteLeadImage(imageId: $imageId) {
      success
    }
  }
`

export const CUSTOMER_DATA = gql`
  ${LEAD_DATA_FRAGMENT}
  ${LEAD_IMAGES_FRAGMENT}
  query GetCustomerData {
    me {
      id
      leadData {
        ...LeadDataFields
      }
      leadImages {
        ...LeadImageFields
      }
    }
  }
`

export const GET_LAST_NAME = gql`
  query GetLastName {
    me {
      leadData {
        lastName
      }
    }
  }
`

export const GET_QR_CODE = gql`
  mutation GetQRCode($baseUrl: String, $redirectTo: LoginRedirectOptions) {
    getLoginCodeViaQrCode(baseUrl: $baseUrl, redirectTo: $redirectTo) {
      qrCode
    }
  }
`

export const SEND_SMS_REMINDER = gql`
  mutation SendSmsReminderWithLoginCode($sendAt: SendAt!, $userId: String!) {
    sendSmsReminderWithLoginCode(sendAt: $sendAt, userId: $userId) {
      success
    }
  }
`

export const BOOK_SALES_APPOINTMENT = gql`
  mutation BookSalesAppointment(
    $customerId: String!
    $date: String!
    $time: String!
    $tracking: Tracking
  ) {
    bookSalesAppointment(
      customerId: $customerId
      date: $date
      time: $time
      tracking: $tracking
    ) {
      success
      customerEmail
      sellerName
      sellerEmail
      eventId
      startDateTime
      location
    }
  }
`

export const GET_APPOINTMENT_BY_GLOBAL_ID = gql`
  query GetAppointmentByGlobalId($customerId: String!, $postalCode: String!) {
    getAppointmentByGlobalId(id: $customerId, postalCode: $postalCode) {
      appointment {
        firstName
        lastName
        mailingCity
        mailingPostalCode
        mailingStreet
        salutation
        startDateTime
        endDateTime
        actualKwhConsumptionPerYear__c
        numberOfCadastreRegistrants__c
        actualElectricityCostsPerYear__c
        location
        sellerName
        status__c
        eventId
      }
      status
    }
  }
`

export const GET_EVENT_BY_GLOBAL_ID = gql`
  query GetEventByGlobalId($customerId: String!, $eventType: Float) {
    getEventByGlobalId(id: $customerId, eventType: $eventType) {
      eventId
      startDateTime
      endDateTime
      status__c
    }
  }
`

export const GET_INSTALLATION_STEPS = gql`
  query GetInstallationByGlobalId($customerId: String!) {
    getInstallationByGlobalId(id: $customerId) {
      installationDate
      installationStatus
      installationType
    }
  }
`

export const GET_SEPA_MANDATE = gql`
  query GetSepaByGlobalId($customerId: String!) {
    getSepaByGlobalId(id: $customerId) {
      creditorIdentificationNumber
      operationsNumber
      spv
    }
  }
`

export const GET_PREVIEW = gql`
  query GetPreview($contract_id: String!, $token: String!) {
    preview(contract_id: $contract_id, token: $token) {
      previewFile
      contractType
      sepaRequired
      hasEnergy
      hasDirectSelling
      sepaPreviewDocumentStatus
      globalCustomerId
      hasServicePayment
      spvName
      isPoAGiven
      signers {
        nameSurname
        address
        zipCity
        email
        isActive
        isSigned
      }
    }
  }
`

export const SEND_SEPA_TO_DOCSIGNER = gql`
  mutation SendSigningSepa(
    $contractId: String!
    $token: String!
    $iban: String!
    $bankName: String!
    $accountOwner: String!
    $ownerAddress: String!
    $taxNumber: String!
    $salesTaxNumber: String
    $salesTaxType: Int!
    $bic: String!
  ) {
    sepa(
      contractId: $contractId
      token: $token
      iban: $iban
      bankName: $bankName
      accountOwner: $accountOwner
      ownerAddress: $ownerAddress
      taxNumber: $taxNumber
      bic: $bic
      salesTaxNumber: $salesTaxNumber
      salesTaxType: $salesTaxType
    ) {
      success
    }
  }
`

export const CREATE_SEPA_BUS = gql`
  mutation CreateSepaBus(
    $customerId: String!
    $iban: String!
    $bankName: String!
    $accountHolder: String!
    $address: String
    $taxNumber: String
    $taxId: String
    $taxRegulation: TaxRegulationType
    $updateType: [BankUpdateType!]
  ) {
    createSepaBus(
      customerId: $customerId
      iban: $iban
      bankName: $bankName
      accountHolder: $accountHolder
      address: $address
      taxNumber: $taxNumber
      taxId: $taxId
      taxRegulation: $taxRegulation
      updateType: $updateType
    ) {
      success
    }
  }
`

export const GET_SIGNING_LINK = gql`
  query GetSigningLink(
    $contractId: String!
    $token: String!
    $signerNumber: Int!
  ) {
    getSigningLink(
      contractId: $contractId
      token: $token
      signerNumber: $signerNumber
    ) {
      signingUrl
    }
  }
`
export const GET_ENVELOPE_REVIEW_URL = gql`
  query GetEnvelopeReviewUrl($contractId: String!, $token: String!) {
    getEnvelopeReviewUrl(contractId: $contractId, token: $token) {
      envelopeReviewUrl
    }
  }
`
export const GET_SIGNING_SEPA_STATUS = gql`
  query GetSigningSepaStatus($contractId: String!, $token: String!) {
    getSigningSepaStatus(contractId: $contractId, token: $token) {
      status
    }
  }
`

export const GET_CADASTRE_REGISTRANTS = gql`
  query GetCadastreRegistrantByGlobalId($customerId: String!) {
    getCadastreRegistrantByGlobalId(id: $customerId) {
      numberOfCadastreRegistrants
      cadastreRegistrant {
        firstName
        lastName
        salutation
        email
      }
    }
  }
`

export const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment(
    $eventId: String!
    $action: CancellationActionType!
  ) {
    updateAppointment(
      action: $action
      eventId: $eventId
      cancellationComment: ""
    ) {
      success
    }
  }
`
export const GET_BANK_DATA_BY_IBAN = gql`
  query GetBankDataByIban($iban: String!) {
    getBankDataByIban(iban: $iban) {
      bankData {
        name
        bic
      }
    }
  }
`
export const GET_BANK_BY_GLOBAL_ID = gql`
  query GetBankByGlobalId($customerId: String!) {
    getBankByGlobalId(id: $customerId) {
      IBAN__c
      bankAccountOwner__c
      serviceContract__C
    }
  }
`

export const GET_OPPORTUNITY_BY_GLOBAL_ID = gql`
  query GetOpportunityByGlobalId($customerId: String!) {
    getOpportunityByGlobalId(id: $customerId) {
      stageName
      isDeleted
      contractSignedDate__c
      orderNumber__c
      isScheduled
    }
  }
`

export const GET_FULLFILMENT_CASE_BY_GLOBAL_ID = gql`
  query GetFulfillmentCaseByGlobalId($customerId: String!) {
    getFulfillmentCaseByGlobalId(id: $customerId) {
      submittedToSPV__c
      setupMonitoringClosed__c
    }
  }
`
export const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableEventSlots($filter: AvailableSlotsQuery!) {
    getAvailableEventSlots(filter: $filter) {
      startDateTime
      endDateTime
      subject
    }
  }
`

export const GET_ACCOUNT_BY_GLOBAL_ID = gql`
  query GetAccountByGlobalId($customerId: String!) {
    getAccountByGlobalId(id: $customerId) {
      collectionTriggerDate__c
      taxNumber__c
      taxType__c
    }
  }
`
