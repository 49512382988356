import { ApolloLink, Operation } from '@apollo/client/core'
import { Logger } from 'pino'

function formatMessage(
  operationType: string,
  operation: Operation,
  ellapsed: number
) {
  const operationName = operation.operationName
  return `[GraphQL] ${operationType} ${operationName} ${ellapsed}ms`
}

const loggerLink = (logger: Logger) =>
  new ApolloLink((operation, forward) => {
    // We will log only server operations
    if (typeof window === 'object') {
      forward(operation)
    }

    const startTime = new Date().getTime()

    return forward(operation).map((result) => {
      const operationType = operation.query.definitions[0]['operation']
      const ellapsed = new Date().getTime() - startTime
      const variables = operation.variables

      logger.info(
        { metadata: { variables, result } },
        formatMessage(operationType, operation, ellapsed)
      )
      return result
    })
  })

export default loggerLink
