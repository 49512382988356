export default class ErrorBase extends Error {
  code: number
  ui?: 'modal' | 'page' | 'overlay'
  name: string

  constructor(
    message: string,
    code: number,
    ui?: 'modal' | 'page' | 'overlay'
  ) {
    super(message)
    this.code = code
    this.ui = ui
    this.name = 'ErrorBase'
  }
}
