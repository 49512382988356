import { LoggerOptions } from 'pino'

export const pinoOptions = {
  level: 'info',
  messageKey: 'message',
  errorKey: 'error',
  redact: ['IBAN__c'],
  mixin() {
    return {
      logger: {
        name: 'pino',
      },
    }
  },
} as LoggerOptions
