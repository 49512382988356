import pino from 'pino'
import { pinoOptions } from './common'

export const pinoOptionsClient = {
  ...pinoOptions,
  msgPrefix: '[CLIENT] ',
  browser: { asObject: true },
  level: process.env.NODE_ENV === 'production' ? 'warn' : 'debug',
}

export default pino(pinoOptionsClient)
