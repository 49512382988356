'use client'

import { setContext } from '@apollo/client/link/context'
import logger from 'logger/client'

const authLink = setContext(async (_, { _headers }) => {
  let id_token = null
  try {
    const data = await fetch('/api/auth/session').then((res) => res.json())
    id_token = data.user?.access_token
  } catch (error) {
    logger.error("Can't get session")
    logger.error(error)
    return {}
  }

  return {
    headers: {
      Authorization: `Bearer ${id_token}`,
    },
  }
})

export default authLink
