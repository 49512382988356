import { onError } from '@apollo/client/link/error'
import { datadogRum } from '@datadog/browser-rum'
import GraphQLError from 'utils/errors/GraphQLError'
import { Logger } from 'pino'

const loggerLink = (logger: Logger) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ extensions, message, path }) => {
        let messageAsString = ''
        if (typeof message === 'object') {
          messageAsString =
            message && message['detail']
              ? message['detail']
              : JSON.stringify(message)
        }

        if (typeof message === 'string') {
          messageAsString = message
        }

        const status = extensions?.exception?.['status'] ?? 500

        const error = new GraphQLError(
          messageAsString,
          status,
          path ? path.join('.') : ''
        )

        const level = status >= 500 ? 'error' : 'warn'

        logger[level]({
          error: {
            message: '[GraphQL ERROR] ' + error.message,
            stack: error.stack,
            kind: 'GraphQLError',
          },
        })

        // If it is a server error, we send it to datadog
        if (status >= 500) {
          datadogRum.addError(error, {
            status,
            path,
          })
        }
      })
    if (networkError) {
      logger.warn(`[Network error]: ${networkError}`)
      datadogRum.addError(networkError)
    }
  })

export default loggerLink
