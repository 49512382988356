import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import logger from 'logger/client'
import loggerLink from 'utils/apollo-links/logger-link'
import authLink from 'utils/apollo-links/auth-link/client'
import errorLink from 'utils/apollo-links/error-link'
import httpLink from 'utils/apollo-links/http-link'

export const client = new ApolloClient({
  link: from([loggerLink(logger), authLink, errorLink(logger), httpLink]),
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
})
